declare let webpack: PlatformGlobalSettings;

window.SA_CONSTANTS = Object.freeze({
  endpoint: webpack.endpoint,
  graphqlGatewayEndpoint: webpack.graphqlGatewayEndpoint,
  env: Object.freeze({
    live: webpack.env.live,
    sentry: webpack.env.sentry,
    track: webpack.env.track,
    admin: webpack.env.admin
  })
});

import('./bootstrap');
